import { FC, useMemo } from 'react';
import { useRecoilValue } from 'recoil';
import { currentUserAtom } from '../../../recoil/atoms/Auth';
import { dataAttributeProps } from '../../../utils/ComponentUtils';
import gradiant from '../../../assets/images/gradient.svg';

type Props = {
  firstName: string;
  lastName: string;
  id?: string;
  sizeCss?: string;
  marginCss?: string;
  className?: string;
};

const ProfileInitials: FC<Props> = (props) => {
  const { id, firstName, lastName, sizeCss = 'w-8 h-8 text-dpm-14', marginCss, className } = props;

  const currentUser = useRecoilValue(currentUserAtom);
  const isCurrentUser = useMemo(() => currentUser?.id === id, [currentUser?.id, id]);

  const initials = useMemo(() => {
    return (firstName?.charAt(0).toUpperCase() || '') + (lastName?.charAt(0).toUpperCase() || '');
  }, [firstName, lastName]);

  return (
    <div
      className={`w-fit rounded-full p-[2px] shadow-md transition-all duration-300 ease-in-out hover:z-20 [&:is([data-user-count]):not([data-user-count="0"]):not([data-user-count="1"])]:hover:-mt-[0.3rem] ${marginCss}`}
      style={{
        backgroundImage: id ? `url("${gradiant}")` : '',
        backgroundColor: id ? '' : 'rgba(var(--color-gray-3), 1)',
        backgroundRepeat: 'no-repeat',
        backgroundSize: '125%',
        backgroundPosition: 'center',
      }}
      {...dataAttributeProps(props)}
    >
      <div
        className={`${className}  ${
          id ? (isCurrentUser ? 'bg-accent-1 text-white' : 'text-gray-2 bg-white') : 'bg-gray-4 text-black'
        } rounded-full border-2 border-transparent pt-[2px] font-medium ${sizeCss} inline-flex cursor-default select-none items-center justify-center`}
      >
        {initials || '\u00A0'}
      </div>
    </div>
  );
};

export default ProfileInitials;
